@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

:root {
  --vh: 100%;
  --white: #fff;
  --focus_purple:#8538ff; 
}

/* 클래스 페이지 (class.pub.js) */
.left-content{
  width: 80%;
  min-width: 1032px;
  padding: 0 10%;
  font-family: 'Pretendard-Regular';
  line-height: 38px;
}

.class_header{
  height: 40px;
  line-height: 40px;
}

.class_title{
  font-size: 20px;
  font-weight: 900;
}

.game_add_btn{
  height: 32px;
}

.listunitbox{
  text-align: start;
  font-weight: 900;
}

.listunitbox li:first-child h2{
  font-size: 20px;
  color: black; 
}



/* 클래스 페이지 모달 */
.class_form.list_popup{
  padding: 32.25px !important;
}

.class_form>li {
  margin-bottom: 24px;
}

.icon.close{
  cursor: pointer;
}

.icon.close svg:hover {
  cursor: pointer;
  filter: invert(27%) sepia(63%) saturate(6154%) hue-rotate(256deg) brightness(103%) contrast(101%);
}

.form{
  margin-bottom: 24px;
}

.form p {
  color: black;
  text-align: left;
  font-weight: 900;
  margin-bottom: 8px;
}

input[type="date"] {
  -webkit-appearance: auto; /* Chrome, Safari, Opera */
  -moz-appearance: auto; /* Firefox */
  appearance: auto; /* Standard */
  all: initial;
  box-sizing: border-box;
}

.form input:focus {
  outline: 1.5px solid var(--focus_purple);
}

.form input::placeholder {
  color: #d3d2d2;
}


#Title, #start_dt{
  background-color: var(--white);
  height: 68px;
  font-size: 20px;
  color: black;
  font-family: 'Pretendard-Regular';
}

.custom-btn{
  height: 70px;
  width: 100%;
  color: black;
  font-size: clamp(1rem, 2vw, 0.5rem);
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5), 7px 7px 20px 0px rgba(0, 0, 0, .1), 4px 4px 5px 0px rgba(0, 0, 0, .1);
}